import AuthenticatedRoute from '../../authenticated';

export default class SettingsMaxBetIndexRoute extends AuthenticatedRoute {

  definer = 'settings_max_bet_configuration';

  queryParams = {
    partner_id: {
      refreshModel: true
    },
  };

  model(params) {
    return params;
  }

  activate() {
    this.partner.optionSystem = true;
    this.partner.selectPartner(0);
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showIsEur = false;
  }

  deactivate() {
    this.partner.optionSystem = false;
    this.partner.selectPartner(this.partner.selectedId);
  }
}
