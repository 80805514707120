import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';
import {inject as service} from '@ember/service';

export default class TournamentSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  @service template;

  entity = 'tournament'

  attrs = {
    gameIds: {embedded: 'always'},
    prizes: {embedded: 'always'},
  };

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'query') {
      const {items, ...rest} = payload

      payload = {
        [`${this.entity}s`]: items || [],
        meta: rest,
        ...rest
      };
    } else if (requestType === 'findRecord') {
      if (payload.name) {
        const [first, second] = payload.name.split(' ');

        payload.nameFirst = first || '';
        payload.nameSecond = second || '';

        delete payload.name;
      }

      if (!payload.gameSubCategoryId && payload.gameIds?.length === 0) {
        payload.gameSubCategoryId = ''
      }

      payload.prizePool = this.template.withNumber(payload.prizePool)
      payload.prizes = payload.prizes.map(prize => ({
        ...prize,
        reward: this.template.withNumber(prize.reward)
      }))

      payload = {
        [`${this.entity}s`]: payload || []
      };
    } else if (requestType === 'createRecord') {
      payload = {
        [`${this.entity}s`]: { ...payload  || null }
      };
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }

  serializeIntoHash(data, type, snapshot, options) {
    Object.assign(data, this.serialize(snapshot, options));
  }

  serialize() {
    let {nameFirst, nameSecond, ...json} = super.serialize(...arguments);

    if (!json.gameSubCategoryId) {
      json.gameSubCategoryId = null
    }

    if (json.gameIds?.length) {
      json.gameIds = json.gameIds.map((gameId => +gameId))
    }

    if (nameFirst || nameSecond) {
      json.name = `${nameFirst || ''} ${nameSecond || ''}`.trim();
    }

    return json;
  }
}
