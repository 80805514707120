import AuthenticatedRoute from '../../authenticated';
import {inject as service} from '@ember/service';
import {task} from "ember-concurrency";

export default class SettingsMaxBetSettingRoute extends AuthenticatedRoute {
  @service store;
  @service partner;

  definer = 'settings_max_bet_configuration';

  model(params) {
    return this.getMaxBetConfigurationTask.perform(params.currency_group_id);
  }

  @task
  * getMaxBetConfigurationTask(id) {
    return yield this.store.queryRecord('max-bet-currency-group', {
      currency_group_id: id,
      partner_id: this.partner.selectedId,
    });
  }

  activate() {
    this.partner.optionSystem = true;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showIsEur = false;
  }

  deactivate() {
    this.partner.optionSystem = true;
    this.partner.showPartnerCurrencySelect = false;
  }
}
